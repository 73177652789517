<template>
  <page-header-wrapper @back="back">
    <template v-slot:title>
      {{ formTitle }}
    </template>
    <a-card :bordered="false">
      <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 2}" :wrapper-col="{ span: 20 }">
        <a-form-model-item label="公告类型" prop="noticeType" >
          <a-select placeholder="请选择" v-model="form.noticeType" style="width: 200px;">
            <a-select-option v-for="(d, index) in dict.type['sys_notice_type']" :key="index" :value="parseInt(d.value)">{{
              d.label }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="status" >
          <a-radio-group v-model="form.bstatus" button-style="solid" @change="changeStatus">
            <a-radio-button v-for="(d, index) in dict.type['sys_notice_status']" :key="index" :value="parseInt(d.value)">{{
              d.label }}</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="标题" prop="noticeTitle">
          <a-input size="large" width="200px" v-model="form.noticeTitle" placeholder="请输入标题" />
        </a-form-model-item>
        <a-form-model-item label="内容" prop="noticeContent">
          <editor v-model="form.noticeContent" />
        </a-form-model-item>
      </a-form-model>
      <a-space style="margin-left: 8.5%">
        <a-button type="primary" :loading="submitLoading" :disabled="submitLoading" @click="submitForm">
          发布
        </a-button>
        <a-button type="dashed" @click="back">
          {{ $t('取消') }}
        </a-button>
      </a-space>
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { getNotice, addNotice, updateNotice } from '@/api/system/notice'
import Editor from '@/components/Editor'
import bus from '@/utils/bus'
import events from '@/components/MultiTab/events'

export default {
  name: 'NoticeForm',
  components: {
    Editor
  },
  dicts: ['sys_notice_status', 'sys_notice_type'],
  data() {
    return {
      wrapperCol: { span: 48 },
      submitLoading: false,
      total: 0,
      id: undefined,
      formTitle: '',
      // 表单参数
      form: {
        id: undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeContent: '',
        status: 1,
        bstatus: 1
      },
      rules: {
        noticeType: [{ required: true, message: '公告类型不能为空', trigger: 'change' }],
        noticeTitle: [{ required: true, message: '公告标题不能为空', trigger: 'blur' }],
        noticeContent: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      open: false
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.id = this.$route.params.id
    this.formTitle = this.$route.params.formTitle
    if (this.id) {
      this.handleUpdate(this.id)
    } else {
      this.handleAdd()
    }
  },
  methods: {
    changeStatus(e) {
      const status = e.target.value
      this.form.status = status === '0' ? '1' : '0'
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeContent: '',
        status: 1,
        bstatus: 1
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.formTitle = '添加公告'
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset()
      const noticeId = id
      getNotice(noticeId).then(response => {
        const str = response.data.status
        this.$set(response.data, 'bstatus', str)
        this.form = response.data
        this.formTitle = '修改公告'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          // this.submitLoading = true
          if (this.form.id !== undefined) {
            updateNotice(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              // 去更新列表页的数据
              bus.$emit('updateNoticeList')
              this.back()
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addNotice(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              bus.$emit('updateNoticeList')
              this.back()
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    back() {
      const pages = this.$store.pages
      pages.indexOf(this.$route)
      events.$emit('close', pages.indexOf(this.$route))
      this.$router.push('/system/notice')
    },
    onClose() {
      this.open = false
    },
    handleSubmit() {
      this.$refs.baseForm.validate(valid => {
        if (valid) {
          this.open = true
        } else {
          return false
        }
      })
    }
    // 关闭当前标签页

  }
}
</script>
